import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import HeaderInfo from "components/_common/Header/HeaderInfo";
import { connect } from "react-redux";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { userAllRolesSlugSelector } from "components/_selectors/authSelectors";

function Error403({userInfo,userAllRoleSlug}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <HeaderInfo title={t("app_name")} />
      {userInfo && userAllRoleSlug.length > 0 && 
        <div className="error-page d-none">
          <div className="main-wrapper">
            <div className="error-box">
              <h1>403</h1>
              <h3 className="h2 mb-3">
                <i className="fas fa-exclamation-circle" /> {t("access_denied")}
              </h3>
              <p className="h4 font-weight-normal">
                {t("access_denied_error_msg")}
              </p>
              <Link
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                {t("go_back")}
              </Link>
            </div>
          </div>
        </div> 
      }
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
    userAllRoleSlug: userAllRolesSlugSelector(state),
  };
};
export default connect(mapStateToProps, {})(Error403);