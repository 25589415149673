import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { mainRoutes, loginRoutes, termsAndUserRoutes } from "config/routing";
import AppLayout from "layouts/AppLayout";
import Error404 from "components/_common/ErrorPages/Error404";
import { PreloaderComponent } from "components/_common/Preloader";
import SigninLayout from "layouts/SigninLayout";
import TermsAndUseLayout from "layouts/TermsAndUseLayout";
import Error403 from "components/_common/ErrorPages/Error403";
import { userAllRolesSlugSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { getUserRoleSlugList } from "utils/StorageHelper";
function AppRouteWrapper({ userAllRoleSlug }) {
  const [userRoles, setUserRoles] = useState(getUserRoleSlugList());
  var routeKeys = Object.keys(mainRoutes);
  var loginRouteKeys = Object.keys(loginRoutes);
  var termsAndUseKeys = Object.keys(termsAndUserRoutes);
  useEffect(() => {
    setUserRoles(userAllRoleSlug);
  }, [userAllRoleSlug]);
  const CheckedAllowedAccess = (routeAllowedRoles) => {
    return routeAllowedRoles.some((role) => userRoles?.includes(role));
  };
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
        <Route element={<AppLayout />}>
          {routeKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={mainRoutes[routeKey].path}
                element={
                  CheckedAllowedAccess(
                    mainRoutes[routeKey].allowedRoles
                  ) ? (
                    mainRoutes[routeKey].element
                  ) : (
                    <Error403 />
                  )
                }
              >
                {mainRoutes[routeKey].routes &&
                  Object.keys(mainRoutes[routeKey].routes).map(
                    (nestedRouteKey, nestedIndex) => (
                      <Route
                        key={nestedIndex}
                        path={mainRoutes[routeKey].routes[nestedRouteKey].path}
                        element={
                          mainRoutes[routeKey].routes[nestedRouteKey].element
                        }
                      />
                    )
                  )}
              </Route>
            );
          })}
        </Route>
        <Route element={<SigninLayout />}>
          {loginRouteKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={loginRoutes[routeKey].path}
                element={loginRoutes[routeKey].element}
              />
            );
          })}
        </Route>
        <Route element={<TermsAndUseLayout />}>
          {termsAndUseKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={termsAndUserRoutes[routeKey].path}
                element={termsAndUserRoutes[routeKey].element}
              />
            );
          })}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
const mapStateToProps = (state) => {
  return {
    userAllRoleSlug: userAllRolesSlugSelector(state),
  };
};
export default connect(mapStateToProps, {})(AppRouteWrapper);
