import React from "react";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavbarLinks from "./NavbarLinks";
import { AppWhileLogo } from "../Icons";
import { t } from "i18next";

function Sidebar(props) {
  const { userInfo } = props;
  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      <>
        {/* <!--Desktop Sidebar Start--> */}
        <nav
          id="sidebarMenu"
          class="col-md-2 col-lg-2 d-none d-md-block bg-primery menu-bg p-0 navbar-vertical"
        >
          <div class="text-center bg-primery text-white pb-3 position-sticky z-1 top-16 border-bottom border-secondary">
            <img src={AppWhileLogo} />
            <br />
            <label class="title">
              {userInfo.user.role.name} {t("login")}
            </label>
            <br />
            <span className="text-break">{userInfo.user.email}</span>
          </div>
          <div class="bg-primery mein-menu sticky-sidebar-desktop">
            <NavbarLinks />
          </div>
          <div class="col-md-2 col-lg-2 d-none d-md-block menu-bg form-group flex-column nav position-fixed bottom-0 text-white">
            <span class="nav-link bg-primery-2 text-light">
              {t("updated_on")}
            </span>
          </div>
        </nav>
        {/*// <!--Desktop Sidebar End-->*/}
      </>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Sidebar);
