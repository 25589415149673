import React, { useState } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "utils/SwalAlertHelper";
import { useTranslation } from "react-i18next";
import { loginRoutes, mainRoutes as routes } from "config/routing";
import { logoutUser } from "store/actions/AuthAction";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { hasBridgerRole } from "utils/StorageHelper";
import FirebaseNotification from "./FirebaseNotification";

function Header(props) {
  const { userInfo } = props;
  const { t } = useTranslation();
  const [showNotification, setShowNotification] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [close, setClose] = useState(false);
  const userHasBridgerRole = hasBridgerRole();
  const navigate = useNavigate();
  const confirmLogout = () => {
    // delete confirmation
    confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      logoutUser(() => navigate(loginRoutes.login.path));
    });
  };
  const handleSearch = () => {
    setClose(true);
    navigate(routes.residentList.path, { state: { searchTerm, close } });
  };
  const handleClose = () => {
    setClose(false);
    setSearchTerm("");
    navigate(routes.residentList.path, { state: { searchTerm: "", close } });
  };
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      // <!-- start search bar -->
      //
      <>
        {/* firebase  notification display start */}
        <FirebaseNotification
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
        {/* firebase notification display end */}
        <div
          class={`${
            userHasBridgerRole
              ? "d-md-flex d-none header-section row pb-3 bg-search offcanvas-sm offcanvas-md position-sticky mt-n16 pt-3 top-0 mb-md-4 z-1"
              : "d-md-flex d-none header-section  row pb-3 bg-search offcanvas-sm offcanvas-md position-sticky mt-n16 pt-3 top-0 mb-md-4 z-1"
          }`}
        >
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9 search-group">
            <div class="input-group">
              <input
                class="form-control border-end-0 border rounded-pill search-btn"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                id="example-search-input"
              />
              <span class="input-group-append">
                {close ? (
                  <button
                    class="btn btn-outline-secondary bg-white border rounded-pill ms-n5"
                    type="button"
                    onClick={handleClose}
                  >
                    <i class="fa fa-close"></i>
                  </button>
                ) : (
                  <button
                    class="btn btn-outline-secondary bg-white border rounded-pill ms-n5"
                    type="button"
                    onClick={handleSearch}
                  >
                    <i class="fa fa-search"></i>
                  </button>
                )}
              </span>
            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2  text-end mt-1">
            <select
              className="border-0 h-100 p-1 rounded-4 w-75"
              onChange={changeLanguage}
              value={i18n.language}
            >
              <option value="en">{t("en")}</option>
              <option value="zh">{t("ch")}</option>
              <option value="ms">{t("ms")}</option>
              <option value="hoc">{t("hoc")}</option>
            </select>
          </div>

          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-end sign-out-group">
            <a
              onClick={confirmLogout}
              className="btn ms-n5 text-white"
              type="button"
            >
              <i className="fa fa-sign-out fs-5" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </>
      // <!-- end search bar -->
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Header);
