import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "utils/StorageHelper";
import { getFirebaseToken } from "services/firebase.service";

// import { getFirebaseToken } from "services/firebase.service";

function HeaderInfo({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    // store user info
    checkLoggedinUser();
  }, []);
  function checkLoggedinUser() {
    var userInfo = getUserInfo();
    if (userInfo) {
      getFirebaseToken(userInfo.fcmToken);
      updateUserState(userInfo);
    } else {
      getFirebaseToken();
    }
  }
  function updateUserState(userInfo) {
    dispatch({
      type: "UPDATE_USER_DETAILS",
      payload: userInfo,
    });
    if (userInfo?.user) {
      dispatch({
        type: "UPDATE_PROFILE_DATA",
        payload: userInfo.user,
      });
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
    </>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(HeaderInfo);
