import React, { useState } from "react";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginRoutes, mainRoutes as routes } from "config/routing";
import NavbarLinks from "./NavbarLinks";
import { useTranslation } from "react-i18next";
import { logoutUser } from "store/actions/AuthAction";
import { AppWhileLogo, home, menu, person, search } from "../Icons";
import { confirmAlert } from "utils/SwalAlertHelper";
import { match } from "path-to-regexp";

function MobileSidebar(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const pattern = routes.viewDashboard.path; // Assuming routes.viewDashboard.path is '/view-dashboard/:id'
  const isSearchResultPage = match(pattern, { decode: decodeURIComponent })(
    location.pathname
  );
  const { userInfo } = props;

  const confirmLogout = () => {
    confirmAlert(t("confirm_logout_msg"), () => {
      logoutUser(() => navigate(loginRoutes.login.path));
    });
  };

  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      <>
        {/* <!-- mobile footer menu start --> */}
        <div className="col-xs-12 col-sm-12 bg-primery d-block d-md-none">
          <div
            className={`${
              isSearchResultPage
                ? "footer-fixed d-none z-1 bg-primery"
                : "footer-fixed z-1 bg-primery"
            }`}
          >
            <footer className="lighten-2">
              <nav className="z-depth-0">
                <div className="nav-wrapper">
                  <ul className="justify text-center">
                    <li>
                      <Link to={routes.dashboard.path}>
                        <img src={home} style={{height:"26px", width: "24px"}}></img>
                        <p>{t("home")}</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.residentList.path}>
                      <img src={person} style={{height:"24px", width: "24px"}}></img>
                        <p>{t("residents")}</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.mobileSerch.path}>
                      <img src={search} style={{height:"24px", width: "24px"}}></img>
                        <p>{t("residents")}</p>
                      </Link>
                    </li>
                    <li className="dropdown-container">
                      <a onClick={toggleDropdown}>
                      <img src={menu} style={{height:"24px", width: "24px"}}></img>
                        <p>{t("more")}</p>
                      </a>
                    </li>
                  </ul>
                  {dropdownOpen && (
                    <div className="dropdown-menu">
                      <button onClick={() => changeLanguage("en")}>
                        {t("english")}
                      </button>
                      <button onClick={() => changeLanguage("zh")}>
                        {t("chinese")}
                      </button>
                      <button onClick={() => changeLanguage("ms")}>
                        {t("malay")}
                      </button>
                      <button onClick={() => changeLanguage("hoc")}>
                        {t("hokkien")}
                      </button>
                      <hr />
                      <button onClick={confirmLogout}>{t("logout")}</button>
                    </div>
                  )}
                </div>
              </nav>
            </footer>
          </div>
        </div>
        {/* <!-- mobile footer menu end --> */}
      </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};

export default connect(mapStateToProps, {})(MobileSidebar);
