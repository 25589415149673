module.exports = {
  CRYPTO_SECRET_KEY: "ztohxhkzvgxzjxgyzzmg",
  API_URL: process.env.REACT_APP_BACKEND_API_URL,
  SERVER_FILE_PATH: process.env.REACT_APP_SERVER_FILE_PATH,
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_APP_CODE: process.env.REACT_APP_APP_CODE,
  ADMIN_ROLE_SLUG: "admin",
  BRIDGER_ROLE_SLUG: "bridger",
  AWS_TEMP_URL_VALIDITY_HOUR: 24,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
  AWS_PROFILE_FOLDER: "profile",
  AWS_WHITE_LABELING_FOLDER: "white_labelling",
  AWS_PRODUCT_FOLDER: "product",
  AWS_CATEGORY_FOLDER: "category",
  AWS_ANNOUNCEMENT_FOLDER: "annoucement",
  AWS_RESIDENT_FOLDER: "resident",
  AWS_OFFER_FOLDER: "offer",
  // firebase
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  GOSHEN_ARMS_LIST: [
    { slug: "Manna Bridge", name: "Manna Bridge" },
    { slug: "Bicycle", name: "Bicycle" },
    { slug: "Baker's Hut", name: "Baker's Hut" },
    { slug: "VIC", name: "VIC" },
    { slug: "Dialect Dance", name: "Dialect Dance" },
  ],
  OUTCOME: [
    { slug: "Not_at_home", name: "Not at home" },
    { slug: "Rejected_gift", name: "Rejected gift" },
    { slug: "Accepted_gift", name: "Accepted gift" },
  ],
  AGE: [
    { slug: "age17Under ", name: "Under 17 years old" },
    { slug: "age18", name: "18-20" },
    { slug: "age21", name: "21-24" },
    { slug: "age25", name: "25-30" },
    { slug: "age31", name: "31-34" },
    { slug: "age35", name: "35-40" },
    { slug: "age41", name: "41-44" },
    { slug: "age45", name: "45-50" },
    { slug: "age51", name: "51-54" },
    { slug: "age55", name: "55-60" },
    { slug: "age61", name: "61-64" },
    { slug: "age65", name: "65-70" },
    { slug: "age71", name: "71-74" },
    { slug: "age75", name: "75-80" },
    { slug: "age81", name: "81-84" },
    { slug: "age85", name: "85-90" },
    { slug: "age91", name: "91 & above" },
  ],
  BACKGROUND: [
    { slug: "Single", name: "Single" },
    { slug: "Married", name: "Married" },
    { slug: "Married_with_kids", name: "Married with kids" },
    { slug: "Migrant_workers", name: "Migrant workers" },
    { slug: "Widow", name: "Widow" },
    { slug: "Kid", name: "Kid" },
    { slug: "Maid", name: "Maid" },
    { slug: "Others", name: "Others" },
  ],
  OUTCOME: [
    { slug: "Received_gift", name: "Received gift" },
    { slug: "Rejected_gift", name: "Rejected gift" },
    { slug: "Received_prayer", name: "Received prayer" },
    { slug: "Shared_the_gospel", name: "Shared the gospel/testimony" },
    {
      slug: "Interested_in_learning_more",
      name: "Interested in learning more",
    },
    { slug: "Reaffirmed_faith", name: "Reaffirmed faith" },
    { slug: "Followup_visit", name: "Follow-Up visit/call requested" },
    { slug: "Referred_to_church", name: "Referred to church" },
    { slug: "Accepted_christ", name: "Accepted Christ" },
    { slug: "Unavailable", name: "Unavailable" },
    { slug: "Others", name: "Others" },
  ],
  RACE: [
    { slug: "Chinese", name: "Chinese" },
    { slug: "Malay", name: "Malay" },
    { slug: "Indian", name: "Indian" },
    { slug: "Eurasian", name: "Eurasian" },
    { slug: "Vietnamese", name: "Vietnamese" },
    { slug: "Burmese", name: "Burmese" },
    { slug: "Filipino", name: "Filipino" },
    { slug: "Indonesian", name: "Indonesian" },
    { slug: "Others", name: "Others" },
  ],
  ROLE: [
    { slug: "admin", name: "Admin" },
    { slug: "bridger", name: "User" },
  ],
  RELIGION: [
    { slug: "Buddhist", name: "Buddhist" },
    { slug: "Taoist", name: "Taoist" },
    { slug: "Muslim", name: "Muslim" },
    { slug: "Hindus", name: "Hindu" },
    { slug: "Sikhism", name: "Sikhism" },
    { slug: "Christian", name: "Christian" },
    { slug: "Catholic", name: "Catholic" },
    { slug: "Others", name: "Others" },
  ],
  VIEWER_PERMISSION_SLUG: "viewer",
  EDITOR_PERMISSION_SLUG: "editor",
  PERMISSION_LIST: [
    { slug: "viewer", name: "Viewer" },
    { slug: "editor", name: "Editor" },
  ],
};
