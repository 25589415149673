import React from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { getUserAuthToken } from "utils/StorageHelper";
import { mainRoutes as routes, loginRoutes } from "config/routing";
import { mainRoutes } from "config/routing";
import { getUserHomePageRoute } from "config/routing";
const ProtectedRoute = ({ userInfo, children }) => {
  const user = getUserAuthToken();
  const location = useLocation();
  const navigate = useNavigate();
  let pathname = location.pathname;
  if (!user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={loginRoutes.login.path} replace />;
  } else {
    // if (
    //   pathname == mainRoutes.adminDashboard.path ||
    //   pathname == mainRoutes.dashboard.path
    // ) {
    //   const homePage = getUserHomePageRoute();
    //   if (
    //     !homePage.includes[
    //       (mainRoutes.adminDashboard.path, mainRoutes.dashboard.path)
    //     ]
    //   ) {
    //     return <Navigate to={homePage} replace />;
    //   } else {
    //     return children;
    //   }
    // } else
    {
      return children;
    }
  }
};
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(ProtectedRoute);
