import api from "config/api";
import { manageAPICall } from "./CommonCrudAction";
import { userInfoSelector } from "components/_selectors/authSelectors";
import store from "store";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import { removeLocalStorage } from "utils/StorageHelper";
import apiService from "services/api.service";
import { getUserInfo, storeUserInfo } from "utils/StorageHelper";
import { BRIDGER_ROLE_SLUG } from "config/config";
import { ADMIN_ROLE_SLUG } from "config/config";

export const loginUser = async (data, callback) => {
  manageAPICall(api.login.url, api.login.method, data, callback);
};
export const forgotPasswordCall = async (data, callback) => {
  manageAPICall(
    api.forgotPassword.url,
    api.forgotPassword.method,
    data,
    callback
  );
};
export const updateProfile = async (data, callback) => {
  manageAPICall(api.updateUser.url, api.updateUser.method, data, callback);
};
export const getProfileDetails = async (data, callback) => {
  manageAPICall(
    api.getProfileDetails.url,
    api.getProfileDetails.method,
    data,
    callback
  );
};
export const logoutUser = async (callback) => {
  var data = {};
  manageAPICall(api.logout.url, api.logout.method, data, (status, response) => {
    if (response) {
      if (response.status) {
        successMessage("You have logged out successfully");
        removeLocalStorage("userInfo");
        store.dispatch({
          type: "UPDATE_USER_DETAILS",
          payload: {},
        });
        callback();
      } else {
        errorMessage(response.message);
      }
    }
  });
};
export const refreshFirebaseToken = async (data) => {
  const response = await manageAPICall(
    api.refreshFirebaseToken.url,
    api.refreshFirebaseToken.method,
    data
  );
  // const response = await APIService.submitData(api.refreshFirebaseToken.url, data);
  if (response) {
    if (response.status) {
      var obj = getUserInfo();
      obj.fcmToken = response.data?.fcmToken;
      storeUserInfo(obj);
    } else {
      console.log("error");
    }
  }
};
export const switchUserRole = () => {
  var obj = getUserInfo();
  obj.user.role = {
    name: "User",
    description: null,
    slug: BRIDGER_ROLE_SLUG,
    status: true,
    deleted_at: null,
    created_at: "2024-06-04T06:09:13.262Z",
    id: "665eaf89d5677b7e02207804dfgdf",
  };
  obj.user.main_role = {
    name: "Admin",
    description: null,
    slug: ADMIN_ROLE_SLUG,
    status: true,
    deleted_at: null,
    created_at: "2024-06-04T06:09:13.262Z",
    id: "665eaf89d5677b7e02207804dfgdf",
  };
  storeUserInfo(obj);
  // redirect to dashboard
};
export const stopDelegation = () => {
  var obj = getUserInfo();
  obj.user.role = obj.user.main_role;
  delete obj.user.main_role;
  storeUserInfo(obj);
  // redirect to dashboard
};
