import Toast from "react-bootstrap/Toast";
import React, { useState } from "react";
import { onMessageListener } from "services/firebase.service";
import { AppLogo } from "components/_common/Icons";
import { useTranslation } from "react-i18next";
import { AppWhileLogo } from "../Icons";

export default function FirebaseNotification(props) {
  const { showNotification, setShowNotification } = props;
  const { t } = useTranslation();
  const [notification, setNotification] = useState({ title: "", body: "" });
  // notification listener
  onMessageListener()
    .then((payload) => {
      setShowNotification(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        zIndex: 100,
        right: 0,
        minWidth: "260px",
      }}
    >
      <Toast
        onClose={() => setShowNotification(false)}
        show={showNotification}
        delay={4000}
        // autohide
        animation
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          maxWidth: 310,
        }}
      >
        <Toast.Header>
          <img
            src={AppWhileLogo}
            className="rounded-4 notification-logo me-1"
            style={{ width: "40px" }}
            alt={t("app_name")}
          />
          <strong className="me-auto notification-header-title">
            {notification.title}
          </strong>
          <small>{t("now")}</small>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast>
    </div>
  );
}
