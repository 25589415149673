const initialstate = {
  userInfo: null,
  settings: {},
  firebaseToken: "",
};

const AuthReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "UPDATE_USER_DETAILS":
      return {
        ...state,

        userInfo: action.payload,
      };
    case "UPDATE_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };
    case "UPDATE_FIREBASE_TOKEN":
      return {
        ...state,
        firebaseToken: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
