const endpoints = {
  // login and profile module
  login: { url: "/social-login", method: "POST" },
  logout: { url: "/logout", method: "POST" },
  forgotPassword: { url: "/forgot-password", method: "POST" },
  updateUser: { url: "/profile", method: "POST" },
  getProfileDetails: { url: "/profile", method: "GET" },
  refreshFirebaseToken: { url: "/fcm-update", method: "POST" },
  //dashboard
  dashboardData: { url: "/dashboard", method: "POST" },
  //export resident
  exportResidentData: { url: "/resident-report", method: "POST" },
  //export audit log
  exportAuditLogData: { url: "/audit-log", method: "POST" },
  //create user
  listCreateUser: { url: "/get-user", method: "POST" },
  addCreateUser: { url: "/user", method: "POST" },
  updateCreateUser: { url: "/user", method: "PATCH" },
  deleteCreateUser: { url: "/user", method: "DELETE" },
  //block
  listBlock: { url: "/get-block", method: "POST" },
  addBlock: { url: "/block", method: "POST" },
  updateBlock: { url: "/block", method: "PATCH" },
  deleteBlock: { url: "/block", method: "DELETE" },
  //resident
  listResident: { url: "/get-resident", method: "POST" },
  addResident: { url: "/resident", method: "POST" },
  updateResident: { url: "/resident", method: "PATCH" },
  deleteResident: { url: "/resident", method: "DELETE" },
  //resident status
  listStatusResident: { url: "/get-resident-status", method: "POST" },
  addStatusResident: { url: "/resident-status", method: "POST" },
  updateStatusResident: { url: "/resident-status", method: "PATCH" },
  deleteStatusResident: { url: "/resident-status", method: "DELETE" },
  //Announcement
  listAnnouncement: { url: "/get-announcement", method: "POST" },
  addAnnouncement: { url: "/announcement", method: "POST" },
  updateAnnouncement: { url: "/announcement", method: "PATCH" },
  deleteAnnouncement: { url: "/announcement", method: "DELETE" },
  //terms and use
  settings: { url: "/settings", method: "POST" },
  //notification
  notifications: { url: "/notifications", method: "POST" },

};
export default endpoints;
