import { t } from "i18next";
import Swal from "sweetalert2";
export const confirmAlert = (msg, callback) => {
  Swal.fire({
    text: msg,
    showCancelButton: !0,
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    customClass: {
      popup: "custom-swal-popup",
      confirmButton: "yes-btn  my-2 px-4 py-2 w-auto mx-2 order-1",
      cancelButton: "no-btn my-2 px-4 py-2 w-auto mx-2 ms-2",
    },
    buttonsStyling: !1,
  }).then(function (result) {
    if (result.isConfirmed) {
      if (callback) {
        callback();
      }
    }
  });
};

export const confirmSaveAlert = (msg, callback) => {
  Swal.fire({
    text: msg,
    allowOutsideClick:false,
    confirmButtonText: t("confirm_save"),
    customClass: {
      htmlContainer: "swal-container-section",
      popup: "custom-swal-popup-save",
      text: "custom-swal-text",
      confirmButton: "create-btn my-2 px-4 py-2 w-auto mx-2",
    },
    buttonsStyling: !1,
  }).then(function (result) {
    if (result.isConfirmed) {
      if (callback) {
        callback();
      }
    }
  });
};
