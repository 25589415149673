import store from 'store';
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from 'utils/ToastNotificationHelper';
import { getUserInfo, storeUserInfo, removeLocalStorage } from 'utils/StorageHelper';
import { useTranslation } from 'react-i18next';
import { capitalizeWord } from 'utils/CommonHelper';
export const getListData = async (apiurl, method, data, callback) => {
  // const response = await APIService.callAPI(apiurl, method, data);
  // if(response){
	//   callback(response.status,response);
  // }
  const response = await APIService.getData(apiurl, data);
  if(response){
	  callback(response.status,response);
  }
};
export const manageAPICall = async (apiurl, method, data, callback) => {
  var response;
  if(method == "GET"){
    response = await APIService.getData(apiurl, data);
  }
  else if(method == "POST"){
    response = await APIService.submitData(apiurl, data);
  }
  else if(method == "PATCH"){
    response = await APIService.submitPatchData(apiurl, data);
  }
  else if(method == "DELETE"){
    response = await APIService.deleteData(apiurl, data);
  }
  else if(method == "POSTFORMDATA"){
    response = await APIService.submitFormData(apiurl, data);
  }
  if(response){
    if(callback){
      callback(response.status,response);
    }
	else{
      return response;
    }
  }
};
// export const countryList = async (params, callback) => {
//   var payload = {
//       ...params,...{sort_by: "name", sort_by_mode: "asc"}
//   };
//   const response = await manageAPICall(api.countryList.url, api.countryList.method, payload);
//   if(response && response.status){
//     callback(response.data);
//   }
// };
export const getBlockObj = (element) => {
  let obj = {
    id: element.id,
    name:element.block+" ("+element.postal_code+")",
    min_storey:element.min_storey,
    max_storey:element.max_storey
  };
  return obj; 
};
export const userList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.listCreateUser.url, api.listCreateUser.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const blockList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.listBlock.url, api.listBlock.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const notificationList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.notifications.url, api.notifications.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const announcementList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.listAnnouncement.url, api.listAnnouncement.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const residenttList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.listResident.url, api.listResident.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const statusResidenttList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.listStatusResident.url, api.listStatusResident.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const dashboard = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.dashboardData.url, api.dashboardData.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};
export const termsAndUse = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(api.settings.url, api.settings.method, payload);
  if(response && response.status){
    callback(response.data);
  }
};