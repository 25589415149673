import { ADMIN_ROLE_SLUG } from "config/config";
import {
  encryptObject,
  decryptObject,
  generateRandomNumber,
} from "./CommonHelper";
import { BRIDGER_ROLE_SLUG } from "config/config";
export const getDeviceId = () => {
  var deviceId = getLocalStorage("deviceId");
  if (deviceId) {
    return deviceId;
  } else {
    deviceId = generateRandomNumber();
    storeLocalStorage("deviceId", deviceId);
    return deviceId;
  }
};
export const storeLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  localStorage.getItem(key);
};

export const getUserInfo = () => {
  var userInfo = localStorage.getItem("userInfo");

  if (userInfo) {
    userInfo = decryptObject(userInfo);
  }
  return userInfo;
};

export const storeUserInfo = (obj) => {
  var encryptedObj = encryptObject(obj);
  storeLocalStorage("userInfo", encryptedObj);
};

export const getUserAuthToken = (key) => {
  var userInfo = getUserInfo();
  var authorization = "";
  if (userInfo) {
    authorization = userInfo?.token;
  }
  return authorization;
};
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
export const getUserAllRoleList = () => {
  var userRoles = [];
  var userInfo = getUserInfo();
  if (userInfo) {
    userRoles = userInfo?.user?.role;
  }
  return userRoles;
};
export const getUserRoleSlugList = () => {
  var userRoles = [];
  var userInfo = getUserInfo();
  if (userInfo) {
    var userAllRoles = getUserAllRoleList();
    userRoles.push(userAllRoles?.slug);
  }
  return userRoles;
};
export const getUserRoleBySlug = (slug) => {
  var userRole = getUserAllRoleList().filter((item) => item.slug == slug);
  return userRole;
};
export const hasAdminRole = () => {
  var hasAdminRoleFlag = false;
  var userRoles = getUserRoleSlugList();
  if (userRoles.length > 0) {
    hasAdminRoleFlag = userRoles.includes(ADMIN_ROLE_SLUG);
  }
  return hasAdminRoleFlag;
};
export const hasBridgerRole = () => {
  var hasRoleFlag = false;
  var userRoles = getUserRoleSlugList();
  if (userRoles.length > 0) {
    hasRoleFlag = userRoles.includes(BRIDGER_ROLE_SLUG);
  }
  return hasRoleFlag;
};
