import React from 'react'
import { Outlet } from "react-router-dom";
function TermsAndUseLayout() {
  return (
    <div class="container-fluid px-5 bg-tou">
    <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5">     
        <Outlet />
        </div>
    </div>
</div>
  )
}

export default TermsAndUseLayout
