import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { AppWhileLogo, google } from "components/_common/Icons";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { getYearFormattedDate } from "utils/DateHelper";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function SigninLayout(props) {
  const { t } = useTranslation();

  return (
    <div class="container-fluid bg-white vh-100">
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  text-center">
          <img src={AppWhileLogo} class="logo" />
        </div>
        </div>
          <Outlet />
    </div>
  );
}
