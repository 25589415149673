import React from "react";
import Spinner from "assets/images/svg/preloader.svg";
import "./loader.css";
import { Spin } from "antd"; 
export function PreloaderComponent() {
  return (
    <section
      style={{
        height: "100vh",
        width: "100%",
        padding: "calc(250px + 1.5625vw) 0",
        textAlign: "center",
      }}
    >
      <img
        style={{
          height: "50px",
          width: "50px",
          margin: "0 auto",
        }}
        src={Spinner}
        alt="Loading"
        draggable={false}
      />
    </section>
  );
}

export function Preloader({loading}) {
  // for entire page, to indicate loading on refresh
  if(loading){
    return (
      <div className="content-loader">
        {/* <span className="loader" /> */}
        <Spin className="spin-loader" size="large"/>
      </div>
    );
  }
  else{
    return null;
  }
}

export function PreloaderLoadMore({ extraClass }) {
  return (
    <div className={`d-flex justify-content-center align-items-center ${(extraClass) ? extraClass:''}`}>
      <span className="loader" />
    </div>
  );
}
