export const BRAND_LIST = [
  { slug: "Lexpure", name: "Lexpure" },
  { slug: "Cruze", name: "Cruze" },
];
export const ROLE_LIST = (t) => {
  return [
    { slug: "dealer", name: t("dealer") },
    { slug: "retailer", name: t("sub_dealer") },
    { slug: "technician", name: t("technician") },
  ];
};