import moment from "moment";
import store from "store";
import { renderStr } from "./CommonHelper";
import { settingsSelector } from "components/_selectors/authSelectors";

export const getCurrentDateTime = () => {
  return moment();
};
export const getFormattedDate = (date, dateFormat = "") => {
  if (date == "-") {
    return date;
  }

  dateFormat = "DD/MM/YYYY";
  return moment(date).format(dateFormat);
};
export const getFormattedDate2 = (date, dateFormat = "") => {
  if (date == "-") {
    return date;
  }

  dateFormat = "DD MMM YYYY";
  return moment(date).format(dateFormat);
};
export const getFormattedTime = (dateTime, timeFormat = "h:mm A") => {
  //  var date = moment().format('YYYY-MM-DD')+" "+time;
  return moment(dateTime).format(timeFormat);
};
// export const getCurrentDate = () => {
//   const currentDate = new Date();
//   return currentDate.toDateString();
// };
export const getCurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};
export const getFormattedDateTime = (date, dateTimeFormat = "") => {
  if (dateTimeFormat == "") {
    dateTimeFormat = "DD/MM/YYYY h:mm A";
  }
  return moment(date).format(dateTimeFormat);
};
export const getServerFormattedDate = (date) => {
  var dateFormat = "YYYY-MM-DD";
  return moment(date).format(dateFormat);
};
export const getYearFormattedDate = (date, dateFormat = "") => {
  if (date == "-") {
    return date;
  }

  dateFormat = "YYYY";
  return moment(date).format(dateFormat);
};
export const convertUTCtoLocalDateTime = (utcDateTime) => {
  const dateObject = new Date(utcDateTime);
  const localDateTime = new Date(dateObject.getTime() + (dateObject.getTimezoneOffset() * 60000));
  
  const yyyy = localDateTime.getFullYear();
  const mm = String(localDateTime.getMonth() + 1).padStart(2, '0');
  const dd = String(localDateTime.getDate()).padStart(2, '0');
  const hh = String(localDateTime.getHours()).padStart(2, '0');
  const min = String(localDateTime.getMinutes()).padStart(2, '0');
  
  return `${yyyy}-${mm}-${dd} ${hh}:${min}`;
};